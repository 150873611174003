﻿import * as React from "react";

import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";

import {
    Grid,
    GridColumn,
} from "@progress/kendo-react-grid";

import {
    State,
} from "@progress/kendo-data-query";

import VaccineNew from "./VaccineNew";
import NavigateToLogin from "./NavigateToLogin";

import { useGetVaccinesQuery } from '../store/sunbright-api';

const initialDataState: State = {
    filter: {
        logic: "and",
        filters: [
        ],
    },
    sort: [{ field: "species", dir: "asc" }],
};

const VaccinesGrid = (props: any) => {
    const { data: vaccines, error: vaccinesError, isLoading: vaccinesLoading } = useGetVaccinesQuery(3);

    const [gridHeight, setGridHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setGridHeight((window.innerHeight - 120) + "px");
    }, [vaccines]);

    const handleResize = () => {
        setGridHeight((window.innerHeight - 100) + "px");
    };

    const [dataState, setDataState] = React.useState<State>(initialDataState);
    const [newVaccine, setNewVaccine] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            {(newVaccine) ?
                (<div>
                    <VaccineNew 
                        onReturn={(submitted: boolean) => setNewVaccine(false)}
                    />
                </div>) :
                (<div>
                    <div className="sds-gridHeader">Vaccines</div>
                    {vaccinesError ? (<NavigateToLogin />) : vaccinesLoading ? <Loader size="large" type="converging-spinner" /> : vaccines ? (
                    <div>
                        <Button
                            themeColor={"primary"}
                            icon="plus-circle"
                            onClick={() => setNewVaccine(true)}
                            style={{ position: "absolute", top: "46px", right: "64px", width: "128px", zIndex:"99" }}>
                        New Vaccine
                        </Button>
                        <Grid
                            style={{ height: gridHeight }}
                            className="vaccineGrid sds-selectable"
                            data={vaccines}
                            sortable={true}
                            filterable={true}
                            resizable={true}
                            reorderable={true}
                            size={"small"}
                        >
                            <GridColumn field="species" title="Species" filterable={true} filter="text" />
                            <GridColumn field="description" title="Description" filterable={true} filter="text" />
                            </Grid>
                        </div>
                    ) : null}
                </div>)
            }
        </React.Fragment>
    );
}
export default VaccinesGrid;

﻿import * as React from "react";
import {
    Toolbar,
    Button,
    ButtonGroup,
} from "@progress/kendo-react-buttons";
import {
    Form,
    Field,
    FormElement,
    FormRenderProps,
    FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

import { StackLayout } from "@progress/kendo-react-layout";
import { requiredValidator } from "./validators";

import { FormDatePicker, FormDropDownList, FormInput } from "./FormComponents";

import { useNewVaccineMutation } from '../store/sunbright-api';

const allSpecies = [
    "Cat",
    "Dog",
];

const catTypes = [
    "Rabies",
    "FVRCP",
    "FeLV",
    "FVRCP/Rabies"
];
const dogTypes = [
    "Rabies",
    "Da2PP",
    "Parvovirus",
    "Lyme",
    "Lepto",
    "Respiratory",
    "DAPP/Lepto"
];
const companies = [
    "Boehringer Ingelheim",
    "Merck",
    "Zoetis"
]
const products: any = {
    CatRabiesBoehringer_Ingelheim: ["Merial Imrab 3", "Merial Imrab 3TF", "BI Purevax 1 year", "BI Purevax 3 year"],
    CatFVRCPBoehringer_Ingelheim: ["BI Purevax Feline 4", "BI Purevax Feline 3"],
    CatFeLVBoehringer_Ingelheim: ["BI Purevax Recombinant FeLV"],
    CatFVRCP_RabiesBoehringer_Ingelheim: ["BI Purevax Feline 4 rabies", "BI Purevax Feline 3 rabies"],

    CatRabiesMerck: [],
    CatFVRCPMerck: ["Nobivac Feline 1 HCP"],
    CatFeLVMerck: [],
    CatFVRCP_RabiesMerck: [],

    CatRabiesZoetis: ["Vanguard Rabies 3 Year 10D"],
    CatFVRCPZoetis: [],
    CatFeLVZoetis: [],
    CatFVRCP_RabiesZoetis: [],

    DogRabiesBoehringer_Ingelheim: ["Merial Imrab 3", "Merial Imrab 3TF"],
    DogDa2PPBoehringer_Ingelheim: ["Merial Recombitek C3 (no parainfluenza)", "Merial Recombitek C4", "BI Recombitek Max 5/L4", "BI Recombitek Max 5- CvK/4L (coronavirus and L4 lepto)"],
    DogParvovirusBoehringer_Ingelheim: ["Merial Recombitek Canine parvo"],
    DogLymeBoehringer_Ingelheim: ["BI Recombitek Lyme"],
    DogLeptoBoehringer_Ingelheim: ["BI Recombitek Max 5/L4", "BI Recombitek Max 5- CvK/4L (coronavirus and L4 lepto)", "Merial Recombitek 4 Lepto"],
    DogRespiratoryBoehringer_Ingelheim: ["BI Recombitek Oral", "BI Recombitek Nasal III"],
    DogDAPP_LeptoBoehringer_Ingelheim: [],

    DogRabiesMerck: [],
    DogDa2PPMerck: ["Nobivac DAPP"],
    DogParvovirusMerck: [],
    DogLymeMerck: [],
    DogLeptoMerck: [],
    DogRespiratoryMerck: [],
    DogDAPP_LeptoMerck: [],

    DogRabiesZoetis: ["Vanguard Rabies 3 year 10D"],
    DogDa2PPZoetis: ["Vanguard Plus 5"],
    DogParvovirusZoetis: [],
    DogLymeZoetis: [],
    DogLeptoZoetis: [],
    DogRespiratoryZoetis: [],
    DogDAPP_LeptoZoetis: ["Vanguard DAPP Plus L4"]
}

export interface VaccineNewProps {
    onReturn?: any;
}

const VaccineNew = ({
    onReturn,
}: VaccineNewProps) => {

    const [newVaccine, response] = useNewVaccineMutation();

    const VaccineForm = React.useRef<any>(null);

    const [formHeight, setFormHeight] = React.useState<string>("100px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
        setFormHeight((window.innerHeight - 90) + "px");
    }, []);

    const handleResize = () => {
        setFormHeight((window.innerHeight - 90) + "px");
    };

    const [species, setSpecies] = React.useState<string>("");
    const [type, setType] = React.useState<string>("");
    const [company, setCompany] = React.useState<string>("");

    const [typeList, setTypeList] = React.useState<string[]>([]);
    const [productList, setProductList] = React.useState<string[]>([]);

    const handleSpeciesChange = (e: DropDownListChangeEvent) => {
        if (e.value === "Cat") {
            setTypeList(catTypes);
        } else if (e.value === "Dog") {
            setTypeList(dogTypes);
        }
        setProductList([]);
        setSpecies(e.value);

        VaccineForm.current.onChange("type", { value: null });
        VaccineForm.current.onChange("product", { value: null });
    }
    const handleTypeChange = (e: DropDownListChangeEvent) => {

        const key:string = species + (e.value.replace("/", "_")) + (company.replace(" ", "_"));
        setProductList(products[key]);
        setType(e.value);

        VaccineForm.current.onChange("product", { value: null });
    }
    const handleCompanyChange = (e: DropDownListChangeEvent) => {

        const key: string = species + (type.replace("/", "_")) + (e.value.replace(" ", "_"));
        setProductList(products[key]);
        setCompany(e.value);

        VaccineForm.current.onChange("product", { value: null });
    }

    const submitForm = (event: FormSubmitClickEvent) => {

        const { isValid } = event;

        if (isValid) {
            const postVaccine = VaccineForm.current.values;

            newVaccine(postVaccine)
                .unwrap()
                .then((payload) => { })
                .catch((error) => {
                    console.log(error)
                });
            onReturn(true);
        }
    }

    return (
        <React.Fragment>

            <div
                style={{ overflow: "auto", marginLeft: "16px" }}
                className={"sds-bold"}
            >
                <Form
                    ref={VaccineForm}
                    onSubmitClick={submitForm}
                    render={(formRenderProps: FormRenderProps) => (
                        <div>
                             <Toolbar>
                                    <Button
                                    icon="save"
                                        onClick={formRenderProps.onSubmit}
                                    >
                                        Save
                                    </Button>
                                <ButtonGroup>
                                    <Button
                                        icon="cancel"
                                        onClick={(e) => onReturn(false)}
                                    >
                                        Cancel
                                    </Button>
                                </ButtonGroup>
                            </Toolbar>
                           <div style={{ alignSelf: "center" }}>
                                <FormElement
                                    style={{ height: formHeight, overflow: "auto", marginLeft: "16px", marginRight: "32px" }}>
                                    <div className={"sds-questionnaire"}>
                                        <fieldset
                                            style={{}}
                                            className={"k-form-fieldset"}
                                        >
                                            <legend
                                                style={{ paddingTop: "24px" }}
                                                className={"k-form-legend"}>
                                                Vaccine
                                            </legend>

                                            <StackLayout gap={24}>
                                                <Field
                                                    label={"Species*"}
                                                    name={"species"}
                                                    component={FormDropDownList}
                                                    data={allSpecies}
                                                    onChange={handleSpeciesChange}
                                                   validator={requiredValidator}
                                                />
                                                <Field
                                                    label={"Type*"}
                                                    name={"type"}
                                                    component={FormDropDownList}
                                                    data={typeList}
                                                    onChange={handleTypeChange}
                                                />
                                                <Field
                                                    label={"Company"}
                                                    name={"company"}
                                                    data={companies}
                                                    component={FormDropDownList}
                                                    validator={requiredValidator}
                                                    onChange={handleCompanyChange}
                                                />
                                            </StackLayout>
                                            <StackLayout gap={24}>
                                                <Field
                                                    label={"Name*"}
                                                    name={"product"}
                                                    component={FormDropDownList}
                                                    data={productList}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    label={"Serial Number*"}
                                                    name={"serialnumber"}
                                                    component={FormInput}
                                                    validator={requiredValidator}
                                                />
                                                <Field
                                                    label={"Expiry Date*"}
                                                    name={"expiry"}
                                                    component={FormDatePicker}
                                                    placeholder=""
                                                    validator={requiredValidator}
                                                />
                                            </StackLayout>
                                        </fieldset>
                                    </div>
                                </FormElement>
                            </div>
                        </div>
                    )}
                />
            </div>
        </React.Fragment>
    );
};
export default VaccineNew;

import * as React from "react";
import {
    Form,
    FormRenderProps,
} from "@progress/kendo-react-form";
import {
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab,
} from "@progress/kendo-react-layout";
import {
    Toolbar,
    Button,
    DropDownButton,
    ButtonGroup,
} from "@progress/kendo-react-buttons";
import { Badge, BadgeContainer } from "@progress/kendo-react-indicators";
import {
    Grid,
    GridColumn,
} from "@progress/kendo-react-grid";

import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { useAppSelector } from "../../store/store";
import { useGetExamQuery } from '../../store/sunbright-api';
import { useEditExamStatusMutation } from '../../store/sunbright-api';

import ExamTab from './ExamTab';
import NavigateToLogin from "../NavigateToLogin";

export interface ExamProps {
    examID: string;
    viewOnly: boolean;
    returnLabel: string;
    onReturn?: any;
}

const Exam = ({
    examID,
    viewOnly,
    returnLabel,
    onReturn,
}: ExamProps) => {

    const auth = useAppSelector((state) => state.auth);
    const [canClose, setCanClose] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.role &&
        (auth.user.role.includes("Admin") || auth.user.role.includes("Veterinarian")));
    const [isAdmin, setIsAdmin] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.role &&
        auth.user.role.includes("Admin"));
    const [isIntake, setIsIntake] = React.useState<boolean>(auth && (auth.user !== undefined) && auth.user.role &&
        auth.user.role.includes("Intake"));

    const [showFinalize, setShowFinalize] = React.useState<boolean>(false);

    const [confirmFinalize, setConfirmFinalize] = React.useState<boolean>(false);
    const [confirmStatus, setConfirmStatus] = React.useState<string>("");

    const [hasServices, setHasServices] = React.useState<boolean>(false);
    const [hasComments, setHasComments] = React.useState<boolean>(false);
    const [hasAttachments, setHasAttachments] = React.useState<boolean>(false);

    const [examHeader, setExamHeader] = React.useState<string>("");

    const [editExamStatus, response] = useEditExamStatusMutation();

    const examTabStripRef = React.useRef<any>(null);

    const { data, error, isLoading } = useGetExamQuery(examID);

    const [selectedTab, setSelectedTab] = React.useState<number>(0);

    const [tabStripHeight, setTabStripHeight] = React.useState<string>("600px");

    React.useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);
    React.useEffect(() => {
        setTabStripHeight((window.innerHeight - 120) + "px");
        if (data) {

            let allServices:string = (data.services && data.services.fields && (data.services.fields.length > 0)) ? data.services.fields[0].result.a : "";
            let closableService: boolean = allServices.includes("Spay Neuter") ||
                allServices.includes("External Vet Clinic Referral") ||
                allServices.includes("Weigh") ||
                allServices.includes("Microchip Implant") ||
                allServices.includes("Nail Trim") ||
                allServices.includes("Grooming") ||
                allServices.includes("Pet Food and Supplies");
            setShowFinalize(canClose && ((data.status === 'Open') || closableService));

            setHasServices(allServices.length > 0);
            setHasComments(data.comments && data.comments.fields && (data.comments.fields.length > 0));
            setHasAttachments(data.attachments && data.attachments.fields && (data.attachments.fields.length > 0));


            let gender = "";
            const genderSplit = data.petgender.split(", ");
            if (genderSplit.length === 1) {
                gender = genderSplit[0].substring(0, 1);
            }
            else if (genderSplit.length === 2) {
                gender = genderSplit[1].substring(0, 1) + genderSplit[0].substring(0, 1)
            }

            let age = ((new Date()).getTime() - (new Date(data.petdob)).getTime()) / (365 * 24 * 60 * 60 * 1000);

            setExamHeader(data.petname + " (" + data.ownerfirst + " " + data.ownerlast + ") / " +
                data.petspecies + " / " + gender + " / " + data.petweight + " kg / " + age.toFixed(2) + " yrs");
        }

    }, [data]);

    const handleResize = () => {
        setTabStripHeight((window.innerHeight - 120) + "px");
    };

    const handleSelectTab = (e: TabStripSelectEventArguments) => {
        setSelectedTab(e.selected);
        if (examTabStripRef && examTabStripRef.current && examTabStripRef.current._element && examTabStripRef.current._element.children)
            examTabStripRef.current._element.children[1].scrollTop = 0;
    };

    const ExamForm = React.useRef<any>(null);

    const actionExam = (action:string) => {
        const postExam = ExamForm.current.values;
        postExam.ExamID = examID;
        postExam.Category = "Status";
        postExam.status = action;

        editExamStatus(postExam)
            .unwrap()
            .then((payload) => { })
            .catch((error) => {
                console.log(error)
            });
        onReturn();
    }

    const closedStatuses: string = "Closed Cancelled NoShow Deleted";

    return (
        <React.Fragment>
        <div>
                {error ? (<NavigateToLogin />) : isLoading ? (<Loader size="large" type="converging-spinner" />) : data ? (
                    <div>
                        {(!viewOnly && confirmFinalize) && 
                            <Dialog title={<div><span className="k-icon k-font-icon k-i-info" style={{ color: "green" }} ></span>&nbsp;Please confirm</div>}
                                onClose={(e) => setConfirmFinalize(false)}>
                                <p style={{ margin: "25px", textAlign: "center" }}>
                                    Closed exams cannot be edited.<br /><br />Are you sure you want to finalize this exam?
                                </p>
                                <DialogActionsBar>
                                    <button
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                        onClick={(e) => actionExam("Closed")}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                        onClick={(e) => setConfirmFinalize(false)}
                                    >
                                        No
                                    </button>
                                </DialogActionsBar>
                            </Dialog>
                        }
                        {(confirmStatus.length > 0) &&
                            <Dialog title={<div><span className="k-icon k-font-icon k-i-info" style={{ color: "green" }} ></span>&nbsp;Please confirm</div>}
                                onClose={(e) => setConfirmStatus("")}>
                                <p style={{ margin: "25px", textAlign: "center" }}>
                                    Are you sure you want to set the status to: {confirmStatus}
                                </p>
                                <DialogActionsBar>
                                    <button
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                        onClick={(e) => actionExam(confirmStatus)}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                        onClick={(e) => setConfirmStatus("")}
                                    >
                                        No
                                    </button>
                                </DialogActionsBar>
                            </Dialog>
                        }
                        {!viewOnly &&
                            <Toolbar>
                                <ButtonGroup>
                                    <Button
                                        className="sds-buttoninbar"
                                        icon="undo"
                                        onClick={(e) => onReturn()}
                                    >
                                        {returnLabel}
                                    </Button>
                                    {(showFinalize && !closedStatuses.includes(data.status)) &&
                                        <Button
                                            className="sds-buttoninbar"
                                            imageUrl="book-closed.svg"
                                            themeColor={"primary"}
                                            style={{ filter: "brightness(1.3)" }}
                                            onClick={(e) => setConfirmFinalize(true)}
                                        >
                                            Finalize Exam
                                        </Button>
                                    }
                                    {(isAdmin && closedStatuses.includes(data.status)) &&
                                        <Button
                                            className="sds-buttoninbar"
                                            imageUrl="book-open.svg"
                                            themeColor={"primary"}
                                            style={{ filter: "brightness(1.3)" }}
                                            onClick={(e) => actionExam("Open")}
                                        >
                                            Open Exam
                                        </Button>
                                    }
                                    {(isAdmin && !closedStatuses.includes(data.status)) &&
                                        <DropDownButton
                                            className="sds-buttoninbar"
                                            text="Set Status"
                                            imageUrl="stamp.svg"
                                            items={["Cancelled", "NoShow", "Deleted"]}
                                            onItemClick={(e) => setConfirmStatus(e.item)}
                                        />
                                    }
                                </ButtonGroup>
                                <div style={{ fontSize: "large", fontWeight: "bold", paddingLeft: "64px" }}>
                                    { examHeader }
                                </div>
                            </Toolbar>
                        }
                    <Form
                        ref={ExamForm}
                        render={(formRenderProps: FormRenderProps) => (
                            <div>
                            </div>
                        )}
                    />
                  <TabStrip
                        ref={examTabStripRef}
                        selected={selectedTab}
                        onSelect={handleSelectTab}
                        keepTabsMounted={false}
                        animation={true}
                        style={{ paddingTop: "4px", height: tabStripHeight }}
                        className={"sds-window"}>
                        <TabStripTab title="Info">
                            <ExamTab
                                examID={examID}
                                examData={data}
                                category="Info"
                                viewOnly={viewOnly }
                                qWidth="120px"
                           />
                        </TabStripTab>
                        {(viewOnly || !isIntake) && <TabStripTab title="Schedule">
                            <ExamTab
                                examID={examID}
                                examData={data}
                                category="Schedule"
                                viewOnly={viewOnly}
                                qWidth="120px"
                            />
                        </TabStripTab>
                        }
                            <TabStripTab
                                title={
                                    hasServices ?
                                        <BadgeContainer>
                                            Services
                                            <Badge position="edge" shape="pill" themeColor="success" />
                                        </BadgeContainer> :
                                    "Services"
                                }>
                                <ExamTab
                                    examID={examID}
                                    examData={data}
                                    category="Services"
                                    viewOnly={viewOnly}
                                    qWidth="120px"
                                />
                        </TabStripTab>
                        <TabStripTab title="Eligibility">
                                <ExamTab
                                    examID={examID}
                                    examData={data}
                                   category="Eligibility"
                                    viewOnly={viewOnly}
                                    qWidth="120px"
                                />
                        </TabStripTab>
                        <TabStripTab title="Waivers">
                                <ExamTab
                                    examID={examID}
                                    category="Waivers"
                                    examData={data}
                                    viewOnly={viewOnly}
                                    qWidth="460px"
                                />
                        </TabStripTab>
                        <TabStripTab title="Questionnaire">
                                <ExamTab
                                    examID={examID}
                                    examData={data}
                                    category="Questionnaire"
                                    viewOnly={viewOnly}
                                    qWidth="360px"
                                />
                            </TabStripTab>
                        {(viewOnly || !isIntake) && < TabStripTab title="Exam Record">
                            <ExamTab
                                examID={examID}
                                examData={data}
                                category="ExamRecord"
                                viewOnly={viewOnly}
                                qWidth="360px"
                            />
                        </TabStripTab>
                        }
                        {(viewOnly || !isIntake) && <TabStripTab title="Vaccination">
                            <ExamTab
                                examID={examID}
                                examData={data}
                                category="Vaccinations"
                                viewOnly={viewOnly}
                                qWidth="120px"
                            />
                        </TabStripTab>
                        }
                            <TabStripTab title={
                                hasComments ? 
                                <BadgeContainer>
                                    Comments
                                    <Badge position="edge" shape="pill" themeColor="error" />
                                    </BadgeContainer> :
                                "Comments"
                            }>
                            <ExamTab
                                examID={examID}
                                examData={data}
                                category="Comments"
                                viewOnly={viewOnly}
                                qWidth="360px"
                            />
                        </TabStripTab>
                            <TabStripTab title={
                                hasAttachments ?
                                    <BadgeContainer>
                                        Attachments
                                        <Badge position="edge" shape="pill" themeColor="success" />
                                    </BadgeContainer> :
                                    "Attachments"
                            }>
                            <ExamTab
                                examID={examID}
                                examData={data}
                                category="Attachments"
                                viewOnly={viewOnly}
                                qWidth="120px"
                            />
                        </TabStripTab>
                        </TabStrip>
                    </div>) : null}
                </div>
       </React.Fragment>
    );
};
export default Exam;
